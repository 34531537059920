<template>
  <span v-if="!Seen">
    <div class="legal-bar">
      <p>En naviguant sur ce site vous acceptez les conditions d'utilisation</p>
      <div class="block-buttons">
        <button @click="accept" class="block-buttons__button">OK</button>
        <button @click="mention" class="block-buttons__button">Mentions</button>
      </div>
    </div>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LegalBar",
  data() {
    return {
      read: this.seen,
    };
  },
  computed: {
    ...mapGetters({
      Seen: "StateRead",
    }),
  },
  methods: {
    ...mapActions(["readed"]),
    accept() {
      this.readed();
      this.read = this.Seen;
    },
    mention() {
      this.readed();
      this.read = this.Seen;
      this.$router.push("/mentions");
    },
  },
};
</script>

<style lang="scss" scoped>
.legal-bar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-darker;
  padding: 10px;
  text-align: center;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  transition: opacity 0.3s ease;
  & p {
    font-size: 0.8em;
    margin-bottom: 0px;
    color: $color-lighter;
  }
  .block-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    &__button {
      margin: 0 5px;
      background-color: $color-primary;
      border: 0.2px outset $color-text;
      border-radius: 2px;
      color: $color-darker;
      width: 90px;
    }
  }
}
</style>
