<template>
  <section class="editPost">
    <UploadPostMedia @img-choice="handlePostImg" />
    <div class="hr"></div>
    <UploadCategory @category-choice="handleCategory" />
    <div class="hr"></div>
    <UploadTag @tag-choice="handleTag" />
    <div class="hr"></div>
    <div class="text-center pb-5">
      <form class="formPost" @submit.prevent="updatePost(Post)">
        <div class="formPost__header container">
          <label for="title">Titre de l'article :</label>
          <input type="text" id="title" name="title" v-model="Post.title" />
        </div>
        <div class="container">
          <editor
            id="content"
            name="content"
            v-model="Post.content"
            api-key="wezg7eiu9tcbllujxscrl6atmtb9bz6l3ts85zumu82k53py"
            :init="{
              selector: '#content',
              height: 1200,
              menubar: false,
              skin: 'oxide-dark',
              content_css: 'dark',
              plugins:
                'preview searchreplace autolink autosave save directionality codesample visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools noneditable help charmap quickbars emoticons',
              codesample_languages: [
                { text: 'PHP', value: 'php' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'typescript', value: 'ts' },
                { text: 'Twig', value: 'twig' },
                { text: 'HTML/XML', value: 'markup' },
                { text: 'CSS', value: 'css' },
                { text: 'SCSS', value: 'scss' },
                { text: 'SASS', value: 'sass' },
                { text: 'SQL', value: 'sql' },
                { text: 'terminal', value: 'apacheconf' },
              ],
              menubar: 'file edit view insert format tools table help',
              toolbar:
                'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save | insertfile image link anchor codesample | ltr rtl | help',
              toolbar_sticky: true,
              codesample_global_prismjs: true,
              link_default_protocol: 'http',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }"
          />
        </div>
        <div class="formPost__publish container">
          <label for="onLine">Publier l'article :</label>
          <input
            type="checkbox"
            id="onLine"
            name="onLine"
            v-model="Post.isPublished"
          />
        </div>
        <div class="container-btn">
          <button type="submit" class="edit_btn">Enregistrer l'article</button>
          <button type="submit" class="delete_btn" @click="openModalDeletePost">
            Supprimer l'article
          </button>
        </div>
      </form>
    </div>
  </section>
  <span v-if="this.modalConfirm">
    <ConfirmModal
      @closeModalConfirm="closeModalConfirm"
      title="UPDATE"
      message="Votre article à été modifié"
      btnExit="ok"
    />
  </span>
  <span v-if="this.modalDeletePost">
    <ModalDeletePost
      @closeModalDeletePost="closeModalDeletePost"
      @deletePost="deletePost"
      :idPost="Post.id"
    />
  </span>
</template>

<script>
import UploadPostMedia from "@/components/plugins/UploadPostMedia.vue";
import UploadCategory from "@/components/plugins/UploadCategory.vue";
import UploadTag from "@/components/plugins/UploadTag.vue";
import ModalDeletePost from "@/components/modals/ModalDeletePost.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { mapGetters, mapActions } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import Prism from "prismjs";

export default {
  name: "UploadPost",
  components: {
    UploadPostMedia,
    UploadCategory,
    UploadTag,
    Editor,
    ModalDeletePost,
    ConfirmModal,
  },
  mounted() {
    // apply Prism highlighting to the rendered content
    Prism.highlightAll();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    ...mapGetters({
      Post: "StatePost",
    }),
  },
  data() {
    return {
      form: {
        title: "",
        content: "",
        isPublished: null,
      },
      category: null,
      postImg: null,
      tags: [],
      modalDeletePost: false,
      modalConfirm: false,
    };
  },
  methods: {
    ...mapActions(["UpdatePost"]),
    handleCategory(data) {
      this.category = data;
    },
    handlePostImg(data) {
      this.postImg = data;
    },
    handleTag(data) {
      this.tags = data.map((tag) => ({ label: tag }));
    },
    async updatePost(post) {
      try {
        const data = {
          id: post.id,
          title: post.title,
          content: post.content,
          isPublished: post.isPublished,
        };
        if (this.postImg !== null && this.postImg["@id"] !== null) {
          data.img = this.postImg["@id"].substring(1);
        }
        if (this.category !== null && this.category.id !== null) {
          data.category = "api/categories/" + this.category.id;
        }
        if (this.tags !== null && this.tags.length !== 0) {
          data.tags = this.tags;
        }
        await this.UpdatePost(data);
        this.modalConfirm = true;
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    openModalDeletePost() {
      this.modalDeletePost = true;
      this.modalConfirm = false;
    },
    closeModalDeletePost() {
      this.modalDeletePost = false;
      this.modalConfirm = false;
    },
    deletePost() {
      this.modalDeletePost = false;
      this.modalConfirm = false;
      this.$router.push("/nubezone");
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hr {
  width: 75%;
  height: 1px;
  margin: 100px auto;
  background-color: $color-primary;
}
.editPost {
  margin-bottom: 100px;
}
.formPost {
  &__header {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    & label {
      text-align: left;
      font-size: 1.9em;
      position: relative;
      width: fit-content;
      left: 10%;
      color: $bold-light-primary;
    }
    & #title {
      width: 80%;
      margin: auto;
      padding: 10px 20px;
      border: 0.1px $color-primary solid;
      border-radius: 20px;
      background-color: $color-text-light;
      color: $color-primary;
      text-align: center;
      font-weight: bolder;
    }
  }
  &__publish {
    margin-top: 50px;
    & input {
      width: 50px;
      height: 40px;
    }
    & label {
      font-size: 2em;
      margin-right: 10px;
    }
  }
}
.edit_btn {
  width: fit-content;
  background: green !important;
  color: $color-text-light !important;
  border: 0.3px $color-text outset;
  border-radius: 3px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  margin: 10px 10px;
  &:hover {
    background: $color-darker !important;
    color: green !important;
    border: 0.3px $color-text inset;
    transform: scale(95%);
  }
}
.delete_btn {
  width: fit-content;
  background: red !important;
  color: $color-text-light !important;
  border: 0.3px $color-text outset;
  border-radius: 3px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  margin: 10px 10px;
  &:hover {
    background: $color-darker !important;
    color: red !important;
    border: 0.3px $color-text inset;
    transform: scale(95%);
  }
}
.container-btn {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
