<template>
  <div class="pb-5 mb-5">
    <div class="container-fluid title-user">
      <h1 class="text-center">Liste des utilisateurs</h1>
    </div>
    <div class="userList">
      <div v-for="user in Users" :key="user.id" class="container">
        <button
          type="submit"
          class="btnUser dashboard_btn mt-5"
          @click="profile(user)"
        >
          <p class="id-p">{{ user.id }}</p>
          <p class="userMail">{{ user.email }}</p>
          <p class="pseudo-p">{{ user.pseudo }}</p>
        </button>
        <!--<div class="container fluid">
        <p class="role-info">Role actuel : {{ user.roles }}</p>
      </div>
      <div class="row">
        <div class="list">
          <div class="p-2">
            <input
              type="radio"
              value="ROLE_AUTHOR"
              @click="addUserId(user.id, 'ROLE_AUTHOR')"
              :name="'radioTag' + user.id"
              :id="'roleAuth' + user.id"
              :checked="form.id === user.id"
              class="input-hidden"
            />
            <label :for="'roleAuth' + user.id">
              <p>Auteur</p>
            </label>
          </div>
          <div class="p-2">
            <input
              @click="addUserId(user.id, 'ROLE_ADMIN')"
              type="radio"
              value="ROLE_ADMIN"
              :name="'radioTag' + user.id"
              :id="'roleAdmin' + user.id"
              :checked="form.id === user.id"
              class="input-hidden"
            />
            <label :for="'roleAdmin' + user.id">
              <p>Admin</p>
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <button class="btn-change" @click="addRole()">changer</button>
      </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MalaUsers",
  components: {},
  computed: {
    ...mapGetters({
      Users: "StateUsers",
    }),
  },
  data() {
    return {
      form: {
        roles: [],
        id: "",
      },
    };
  },
  methods: {
    ...mapActions(["GetProfile", "AddRole"]),
    async profile(user) {
      await this.GetProfile(user.id);
      this.$router.push({
        name: "profile",
        params: { pseudo: user.pseudo },
      });
    },
    addUserId(userId, role) {
      this.form.roles = [];
      this.form.id = "";
      this.form.roles = [role];
      console.log(this.form.roles);
      this.form.id = userId;
    },
    async addRole() {
      await this.AddRole(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.btnUser {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  & p {
    margin-bottom: 0px;
  }
  & .userMail {
    color: $color-darker;
  }
}
.dashboard_btn {
  position: relative;
  width: 100%;
  padding: 5px 20px;
  border-radius: 100vw;
  background-color: $color-primary;
  color: $color-text;
  border: solid 1px rgba($color: #fafafa, $alpha: 0.3);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // force safari to respect border radius

  .button-text {
    position: relative;
    z-index: 2;
    & a {
      color: $color-text-light;
      font-size: 0.8em;
    }
  }

  .fill-container {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding-bottom: 100%;
    transform: translateY(-50%) rotate(180deg);

    // collor filling
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-darker;
      border-radius: 50%;
      transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
      transform: translateY(-100%);
    }
  }

  &:hover {
    border-color: $color-secondary;
    transform: translateY(-4px);

    .fill-container {
      transform: translateY(-50%) rotate(0);

      &::after {
        transform: translateY(0);
      }
    }
  }
  & .id-p {
    background-color: $color-darker;
    color: $color-primary;
    padding: 5px 10px;
    border-radius: 20%;
  }
  & .pseudo-p {
    background-color: $color-darker;
    color: $color-primary;
    padding: 3px 7px;
    border-radius: 4px;
  }
}
.role-info {
  width: fit-content;
  background-color: $color-darker;
  color: $color-primary;
  padding: 5px;
  margin: 10px;
  border-radius: 8px;
  font-size: 0.8em;
  @media screen and (min-width: 992px) {
    font-size: 1em;
    padding: 20px;
  }
}
.btn-change {
  width: fit-content;
  background-color: green;
  color: $color-text-light;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    scale: 96%;
    background: red;
  }
}
.title-user {
  margin: 70px 0px 90px;
  display: flex;
  justify-content: center;
}
</style>
