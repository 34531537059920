<template>
  <div class="container mt-5 py-5">
    <!--Section: Content-->
    <section class="dark-grey-text mb-5 contact">
      <!-- Section heading -->
      <h3 class="font-weight-bold text-center mt-2 mb-4 image-fade-in">
        Demandez votre démo gratuitement
      </h3>
      <!-- Section description -->
      <p class="text-center w-responsive mx-auto pb-5 image-fade-in">
        Vous êtes intéressé par la
        <strong>formation continue en entreprise</strong>,
        <strong>la communication interne entre vos équipes</strong>,
        <strong>le coaching</strong> ou toutes autres
        <strong>formations internes</strong> de vos employés pour faciliter leur
        intégration et leur autonomie au quotidien ? parlons-en !
      </p>

      <!-- Grid row -->
      <div class="row contact__form">
        <!-- Grid column -->
        <div class="col-lg-5 mb-lg-0 pb-lg-3 mb-4 slide-left">
          <!-- Form with header -->
          <div class="card">
            <div class="card-body">
              <!-- Header -->
              <div class="form-header blue accent-1">
                <h3 class="mt-2">
                  <i class="fas fa-envelope"></i> Écrivez-nous :
                </h3>
              </div>
              <p class="dark-grey-text">
                on répondra à vos questions, et c'est gratuit !
              </p>
              <!-- Body -->
              <form ref="formData" @submit.prevent="sendEmail">
                <div class="col">
                  <input
                    type="hidden"
                    name="reason"
                    value="Demande de renseignement"
                    required
                  />
                </div>
                <div class="md-form">
                  <i class="fas fa-user prefix grey-text"></i>
                  <input
                    type="text"
                    id="form-name"
                    maxlength="30"
                    placeholder="Votre Nom"
                    name="form-name"
                    required
                    class="form-control"
                  />
                  <label for="form-name">Nom</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-envelope prefix grey-text"></i>
                  <input
                    type="text"
                    id="form-email"
                    name="form-email"
                    placeholder="Adresse mail"
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    class="form-control"
                  />
                  <label for="form-email">Email</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-phone prefix grey-text"></i>
                  <input
                    type="tel"
                    id="form-phone"
                    placeholder="Votre Téléphone"
                    name="form-phone"
                    required
                    pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
                    class="form-control"
                  />
                  <label for="form-phone">Téléphone</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-pencil-alt prefix grey-text"></i>
                  <textarea
                    id="form-text"
                    name="form-text"
                    class="form-control md-textarea"
                    rows="3"
                    pattern="^[^/<>\n]*$"
                    maxlength="450"
                    required
                    placeholder="J'aimerais en savoir plus !"
                  ></textarea>
                  <label for="form-text">Message</label>
                </div>
                <div class="text-center mt-2">
                  <span v-if="this.isCaptchaVisible">
                    <vue-hcaptcha
                      sitekey="e35a3d02-8d4c-44a8-b280-a3445796eb2e"
                      @verify="onVerify"
                      @error="onError"
                    ></vue-hcaptcha>
                  </span>
                  <button class="btn btn-light-blue">Envoyer</button>
                </div>
              </form>
              <span v-if="this.modalConfirm">
                <ConfirmModal
                  @closeModalConfirm="closeModalConfirm"
                  title="Merci pour votre confiance !"
                  message="Vous allez être recontactés dans les plus brefs délais"
                  btnExit="À bientôt"
                />
              </span>
            </div>
          </div>
          <!-- Form with header -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-7 slide-right">
          <!--Google map-->
          <div
            id="map-container-section"
            class="z-depth-1-half map-container-section mb-4"
            style="height: 400px"
          >
            <iframe
              src="https://maps.google.com/maps?q=paris&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              style="border: 0"
              allowfullscreen
              title="localisation"
            ></iframe>
          </div>
          <!-- Buttons-->
          <div class="contact__info">
            <ContactCard />
            <SocialMedia />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import ContactCard from "@/components/partials/ContactCard.vue";
import SocialMedia from "@/components/partials/SocialMedia.vue";
export default {
  name: "contactForm",
  components: { VueHcaptcha, ConfirmModal, ContactCard, SocialMedia },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
      showOrder: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendCallMeMail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendCallMeMail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
      this.showOrder = false;
    },
    showModalOrder() {
      this.showOrder = true;
    },
    closeModalOrder() {
      this.$emit("closeModalOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
h3,
p {
  color: $color-lighter;
}
.contact {
  &__form {
    & .card {
      background: $color-primary;
      & h3 {
        color: $color-darker;
      }
      & p {
        color: $color-darker;
      }
      & .md-form {
        margin-top: 20px;
      }
    }
    & i {
      color: #fff;
    }
    & label {
      color: $color-darker;
    }
    & button {
      border: 1px outset $color-text;
      color: $color-darker;
      margin: 70px 0px 20px;
      &:hover {
        border: 1px inset $color-text;
        background: $color-darker;
        color: $color-primary;
      }
    }
  }
  &__info {
    background: $color-darker;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.map-container-section {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-section iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
