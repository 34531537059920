<template>
  <section class="info-section">
    <div class="info-container">
      <!-- First Div -->
      <div class="info-card my-5">
        <aside class="info-image">
          <div class="image-background"></div>
          <img
            src="@/assets/pictures/monitoring-hand.webp"
            alt="hand-touch-screen"
            class="img-fluid"
          />
        </aside>
        <div class="info-text">
          <h2>Monitoring de l’acquisition des connaissances</h2>
          <p>
            Monitorez l’activité de vos collaborateurs sur l’application afin de
            suivre leur évolution sur notre back-office. En surveillant
            régulièrement l’activité de vos collaborateurs sur l’application,
            vous serez en mesure de suivre leur évolution et de les soutenir
            dans leur développement professionnel. Cette approche proactive vous
            permettra de les guider efficacement et de les aider à atteindre
            leurs objectifs.
          </p>
          <button @click="handleLearnMore">En savoir plus >></button>
        </div>
      </div>

      <!-- Second Div -->
      <div class="info-card reverse my-5">
        <aside class="info-image">
          <div class="image-background"></div>
          <img
            src="@/assets/pictures/social-code.webp"
            alt="many-logo-social-app"
            class="img-fluid"
          />
        </aside>
        <div class="info-text">
          <h2>Marque employeur 3.0</h2>
          <p>
            Nos vidéos utilisent les codes des réseaux sociaux pour parler aux
            candidats internes, collaborateurs afin de rendre les postes plus
            accessibles et attrayantes. En combinant l’aspect ludique des
            réseaux sociaux avec des informations pertinentes et utiles, nous
            facilitons la transmission et l’engagement des utilisateurs de
            l’application. Grâce à cette approche novatrice, vos collaborateurs
            pourront mieux comprendre les différentes opportunités qui s'offrent
            à eux en interne et leur donner des perspectives professionnelles.
          </p>
          <button @click="handleLearnMore">En savoir plus >></button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    handleLearnMore() {
      router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
.info-section {
  padding: 50px 20px;
  margin: 100px auto;
}

.info-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.info-text {
  flex: 1;
  padding: 20px;
  h2 {
    color: $color-lighter;
  }
  p {
    color: $color-text;
  }
}

.info-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    height: 300px;
  }

  /* Background effect */
  .image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(15px, 15px); /* Shift for perspective */
    z-index: -1;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.reverse {
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse;
  }
}

button {
  background-color: $color-darker;
  color: $color-primary;
  border: 0.5px solid $color-primary;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: $color-primary;
  color: $color-darker;
}
</style>
