<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | youcanlearn` : `youcanlearn`
    }}</template>
  </metainfo>

  <NavBase v-if="showNavbar" />
  <!--<NavBlog />-->
  <router-view :key="$route.fullPath" />
  <div v-if="showFooter">
    <LegalBar />
    <FooterBase />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import NavBase from "@/components/NavBase.vue";
//import NavBlog from "@/components/NavBlog.vue";
import LegalBar from "@/components/plugins/LegalBar.vue";
import FooterBase from "@/components/FooterBase.vue";

export default {
  components: { NavBase, FooterBase, LegalBar },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr" },
      description: [
        {
          content:
            "Découvrez YouCanLearn, l’application de micro learning dédiée à la formation entreprise. Des formations courtes et engageantes pour améliorer la qualité de vie au travail et booster la qvt de vos équipes.",
        },
      ],
    });
  },
  data() {
    return {
      showNavbar: true,
      showFooter: true,
    };
  },
};
</script>

<style lang="scss"></style>
