<!--<template>
  <div class="home">
    <HomeHeader />
    <CardCross />
    <HomeService />
    <CallAction />
    <HomeAbout />
    <HomeContact />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HomeHeader from "@/components/home/HomeHeader.vue";
import CardCross from "@/components/home/CardCross.vue";
import HomeService from "@/components/home/HomeService.vue";
import CallAction from "@/components/home/CallAction.vue";
import HomeAbout from "@/components/home/HomeAbout.vue";
import HomeContact from "@/components/home/HomeContact.vue";
export default {
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  components: {
    HomeHeader,
    CardCross,
    HomeService,
    CallAction,
    HomeAbout,
    HomeContact,
  },
  setup() {
    useMeta({
      title: "Créations sites internet",
      description: [
        {
          content:
            "Malaweb, votre partenaire digital de confiance. Nous offrons des solutions web sur mesure pour booster votre présence en ligne. Experts en conception de sites web, référencement SEO, développement d'applications et bien plus. Contactez-nous dès maintenant pour propulser votre entreprise vers le succès digital.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr",
        },
      ],
    });
  },
};
</script>-->

<template>
  <div>
    <HeaderComponent />
    <FeatureSection />
    <IaSection />
    <HeroSection />
    <AboutTrusted />
    <AboutTeam />
    <TestimonialSection />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderComponent from "@/components/ycl/HeaderComponent.vue";
import HeroSection from "@/components/ycl/HeroSection.vue";
import FeatureSection from "@/components/ycl/FeatureSection.vue";
import AboutTrusted from "@/components/about/AboutTrusted";
import AboutTeam from "@/components/about/AboutTeam.vue";
import TestimonialSection from "@/components/ycl/TestimonialSection.vue";
import IaSection from "@/components/ycl/IaSection.vue";

export default {
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  components: {
    HeaderComponent,
    HeroSection,
    FeatureSection,
    IaSection,
    AboutTrusted,
    AboutTeam,
    TestimonialSection,
  },
  setup() {
    useMeta({
      title: "Micro Learning pour la Formation en Entreprise",
      description: [
        {
          content:
            "Découvrez YouCanLearn, l’application de micro learning dédiée à la formation entreprise. Des formations courtes et engageantes pour améliorer la qualité de vie au travail et booster la qvt de vos équipes.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.youcanlearn.fr",
        },
      ],
    });
  },
};
</script>

<style>
/* Ajouter des styles globaux ici si nécessaire */
</style>
