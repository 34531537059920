<template>
  <header>
    <div class="card">
      <div class="card-body text-center">
        <h1 class="card-title text-center">
          {{ post.title }}
        </h1>
        <div class="hr"></div>
      </div>
    </div>

    <!-- <div v-html="post.content"></div>-->
    <div class="card">
      <div class="card-body text-center">
        <div class="card-body__header">
          <div>
            <p class="card-category text-uppercase">
              {{ post.category.name }}
            </p>
          </div>
          <div class="card-date">
            <div v-if="post.updatedAt !== '' && post.updatedAt !== null">
              <p>
                modifié le: <br />
                <time :datetime="post.updatedAt">{{
                  formatDate(post.updatedAt)
                }}</time>
              </p>
            </div>
            <div v-else>
              <p>
                publié le: <br />
                <time :datetime="post.publishedAt">{{
                  formatDate(post.publishedAt)
                }}</time>
              </p>
            </div>
          </div>
        </div>

        <div class="tags">
          <div class="tag" v-for="tag in post.tags" :key="tag.id">
            <strong>{{ tag.label }}</strong>
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-5 mt-4" />
  </header>
  <img
    :src="'https://api-blog.youcanlearn.fr' + post.img.contentUrl"
    alt="Sample image"
    class="header-img"
  />
  <section class="container mb-5 text-white post">
    <div v-html="post.content"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import { useMeta } from "vue-meta";
import { toRef } from "vue";

import Prism from "prismjs";
export default {
  name: "PostRead",
  setup(props) {
    const post = toRef(props, "post");
    /*const processContentForMetaDescription = (content, maxLength = 160) => {
      const contentWithoutStyles = content.replace(
        /<style([\s\S]*?)<\/style>/gi,
        ""
      );
      const contentWithoutTags = contentWithoutStyles.replace(/<[^>]+>/g, "");

      let metaDescription = contentWithoutTags.slice(0, maxLength);
      if (contentWithoutTags.length > maxLength) {
        metaDescription += "...";
      }

      return metaDescription;
    };*/
    const he = require("he");

    const processContentForMetaDescription = (content, maxLength = 160) => {
      const contentWithoutStyles = content.replace(
        /<style([\s\S]*?)<\/style>/gi,
        ""
      );
      const contentWithoutTags = contentWithoutStyles.replace(/<[^>]+>/g, "");

      const escapedContent = he.decode(contentWithoutTags); // Décoder les entités HTML spéciales

      let metaDescription = escapedContent.slice(0, maxLength);
      if (escapedContent.length > maxLength) {
        metaDescription += "...";
      }

      return he.decode(metaDescription); // Réencoder pour éviter les entités HTML non sécurisées
    };

    const metaDescription = processContentForMetaDescription(
      post.value.content
    );

    useMeta({
      title: post.value.title,
      description: [
        {
          content: metaDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.youcanlearn.fr/" + post.value.slug,
        },
      ],
    });
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate(this.post.updatedAt);
    },
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  mounted() {
    // Sélectionne toutes les balises img contenues dans la balise div avec la classe "post"
    const postImages = document.querySelectorAll(".post img");

    // Parcourt chaque balise img dans la classe "post"
    postImages.forEach((img) => {
      // Supprime toutes les balises style de l'image
      img.removeAttribute("style");

      // Ajoute la classe img-fluid et img-post à l'image
      img.classList.add("img-fluid", "img-post");

      // Crée une nouvelle div parent
      const wrapper = document.createElement("div");
      wrapper.classList.add("cadre-img");

      // Insère la nouvelle div avant l'image existante
      img.parentNode.insertBefore(wrapper, img);

      // Déplace l'image à l'intérieur de la nouvelle div
      wrapper.appendChild(img);
      // apply Prism highlighting to the rendered content
      Prism.highlightAll();
    });
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $background;
  border-radius: 5px;
  border: none;
  .card-title,
  .card-date {
    @include text-gradient(#f1eeee, #feda15);
  }
}
.card-body {
  background-color: $color-darker;
  border-top: 1px $color-primary solid;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    .card-category {
      font-size: 0.7em;
      margin-right: 4px;
      padding: 0.5px 5px;
      border: 0.5px $color-primary outset;
      border-radius: 3px 9px;
      background-color: $color-darker;
      color: $color-text;
    }
    .card-date {
      font-size: 0.5em;
    }
  }
  .tags {
    font-size: 0.6em;
    display: flex;
    .tag {
      display: block;
      margin-right: 4px;
      padding: 0.5px 4px;
      border: 0.5px $color-primary outset;
      border-radius: 9px 3px;
      background-color: $color-lighter;
      color: $color-darker;
    }
  }
  .card-title {
    margin: 20px 5px;
    font-size: 1.3em;
    text-align: start;
    color: $color-text-grey;
    @media screen and (min-width: 992px) {
      font-size: 1.1em;
    }
  }
  .hr {
    width: 80%;
    height: 1px;
    background-color: $color-lighter;
    margin: 20px auto;
  }
}
.header-post {
  width: 100vw;
  height: 40vh;
  @media screen and (min-width: 992px) {
    height: 70vh;
    justify-content: start;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  border-top: 0.5px outset $color-primary;
  & img {
    position: absolute;
    min-height: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 1;
  }
}
.post {
  overflow: hidden;
  padding: 30px;
  position: relative;
  & p {
    color: white !important;
  }
  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}
.header-img {
  max-width: 100%;
  max-height: 70vh; // Limite la hauteur à 70% de la hauteur de la fenêtre
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}
</style>
