<template>
  <div class="contact-card mt-4">
    <h5 class="jaapokki text-center mb-3">Contactez-nous</h5>
    <ul class="list-unstyled">
      <li>
        <a href="tel:+33698543586">
          <div>
            <i class="fa fa-mobile"></i>
            <p>06 98 54 35 86</p>
          </div>
        </a>
      </li>
      <li>
        <a href="mailto:dev@youcanlearn.app">
          <div>
            <i class="fas fa-envelope"></i>
            <p>dev@youcanlearn.app</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "contactCard",
};
</script>

<style lang="scss" scoped>
.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & li {
    margin-bottom: 10px;
    border: 2px $color-logo outset;
    border-radius: 7%;
    width: fit-content;
    font-size: 0.6em;
    &:hover {
      border: 2px $color-logo inset;
      color: $color-text;
      & i {
        color: $color-secondary;
      }
    }
  }
  & div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  & a,
  router-link {
    color: rgba(230, 221, 221, 0.904);
    &:hover {
      color: $color-secondary;
    }
    & p {
      margin: 5px;
    }
  }
  & i {
    color: $color-primary;
    font-size: 15px;
    margin-left: 10px;
    margin-right: 8px;
  }
}
</style>
