<template>
  <div class="mentions-legales container my-5 py-5">
    <h1 class="mb-4">Mentions légales :</h1>
    <p>
      Le site internet youcanlearn.fr est la propriété de la société SAS
      Improvisaction – SIRET 91104403000011, dont le siège social ce situe à
      Paris, 37 RUE DES MATHURINS, 75008, France.
    </p>
    <p>Adresse de contact : contact.improvisaction@gmail.com</p>
    <p>
      Le site internet a été réalisé par l'agence web Malaweb, située à Les
      Mureaux (78), France.
    </p>
    <p>Adresse de contact : contact.malaweb.fr</p>
    <p>Hébergement du site :</p>
    <p>
      OVH, 2 rue Kellermann - 59100 Roubaix - France, immatriculée au Registre
      du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419
      00045.
    </p>
    <h2 class="mt-5 mb-4">Protection des données personnelles :</h2>
    <p>
      improvisaction s'engage à respecter la législation française et européenne
      en matière de protection des données personnelles, notamment le Règlement
      Général sur la Protection des Données (RGPD).
    </p>
    <p>
      Les données personnelles collectées par improvisaction sont celles que
      vous fournissez lors de vos correspondance sur le site youcanlearn.fr,
      demande de devis, formulaire de contact (nom, prénom, adresse e-mail,
      numéro de téléphone).
    </p>
    <p>
      Ces données sont conservées pendant la durée nécessaire à la gestion de
      votre demande. improvisaction ne partage vos données personnelles avec
      aucun partenaire .
    </p>
    <p>
      improvisaction utilise Google Analytics pour mesurer le trafic sur le site
      internet, mais ne partage jamais les informations ni ne les utilise à des
      fins publicitaires.
    </p>
    <p>
      Vous disposez d'un droit d'accès, de rectification, de suppression et de
      portabilité de vos données personnelles. Vous pouvez exercer ces droits en
      contactant improvisaction à l'adresse de contact indiquée ci-dessus.
    </p>
    <h2 class="mt-5 mb-4">Copyright :</h2>
    <p>
      Copyright © Malaweb. Tous droits réservés. Toute reproduction totale ou
      partielle du contenu de ce site, par quelque moyen que ce soit, sans
      autorisation préalable de Malaweb est interdite et constituerait une
      contrefaçon sanctionnée par les articles L335-2 et suivants du Code de la
      Propriété Intellectuelle.
    </p>
    <h2 class="mt-5 mb-4">Loi applicable et juridiction compétente :</h2>
    <p>
      Les présentes mentions légales sont régies par la loi française. Tout
      litige relatif à l'interprétation ou à l'exécution des présentes mentions
      légales sera de la compétence exclusive des tribunaux français.
    </p>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  name: "MentionLegale",
  setup() {
    useMeta({
      title: "Mentions Légales",
      description: [
        {
          content:
            "youcanlearn s'engage à respecter la législation française et européenne en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD).",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
h1,
h2 {
  color: $color-primary;
}
p {
  color: $color-lighter;
}
</style>
