<template>
  <div class="container">
    <input type="checkbox" id="toggle" checked />
    <label class="button" for="toggle">
      <nav class="nav">
        <ul>
          <!-- Home Icon -->
          <li>
            <a href="/"><i class="fas fa-home"></i></a>
          </li>
          <!-- Contact Icon -->
          <li>
            <a href="/about"><i class="fas fa-question"></i></a>
          </li>
          <!-- Download Icon -->
          <li>
            <a href="/contact"><i class="fas fa-envelope"></i></a>
          </li>
          <!-- Send Icon -->
          <li>
            <a href="/blog"><i class="fas fa-plus"></i></a>
          </li>
        </ul>
      </nav>
    </label>
    <div>
      <img
        src="@/assets/brand/ycl.webp"
        alt="logo youcanlearn"
        class="logo-brand"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const isOpen = ref(false); // État du menu

    return {
      isOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
$darkbg: #1c1c1c;
$btCinza: #222;
$icons: $color-primary;
$branco: #fff;

.logo-brand {
  position: absolute;
  top: 36px;
  left: 115px;
  width: 150px;
  @media (min-width: 992px) {
    width: 244px;
    top: 27px;
  }
}

.container {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

#toggle {
  -webkit-appearance: none;
}

.button {
  margin: 20px 0px 15px 15px;
  z-index: 999;
  width: 320px;
  height: 65px;
  background: $btCinza;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 24px;
  overflow: hidden;
  transition: width 300ms linear;

  &:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: $icons;
    transform: rotate(225deg);
    transition: all 0.4s ease;
  }
  &:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: $icons;
    transform: rotate(135deg);
    transition: all 0.4s ease;
  }
}

.nav {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  background: $btCinza;
  width: 100%;
  border-radius: 5px;
  transform: translateX(10%);
  padding: 10px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  li {
    opacity: 0;
    list-style: none;
    &:nth-child(1) {
      transform-origin: bottom;
      animation: itop 300ms 300ms linear forwards;
    }
    &:nth-child(2) {
      transform-origin: bottom;
      animation: itop 300ms 400ms linear forwards;
    }
    &:nth-child(3) {
      transform-origin: bottom;
      animation: itop 300ms 500ms linear forwards;
    }
    &:nth-child(4) {
      transform-origin: bottom;
      animation: itop 300ms 600ms linear forwards;
    }
  }
  a {
    transition: all 0.5s linear;
    text-decoration: none;
    color: $color-lighter;
    font-size: 20px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    border-radius: 15px;

    &:hover {
      color: $branco;
      background: $darkbg;
      border-radius: 15px;
    }
  }
}

#toggle:checked ~ label .nav {
  display: none;
  opacity: 0;
  transform: translateX(0);
}

#toggle:checked ~ .button:before {
  transform: rotate(90deg);
}

#toggle:checked ~ .button:after {
  transform: rotate(0deg);
}

#toggle:checked ~ .button {
  width: 70px;
  transition: all 0.1s linear;
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }
}

@keyframes itop {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
