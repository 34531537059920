<template>
  <div class="container z-depth-1 my-5 p-lg-5">
    <!-- Section -->
    <section class="about-service">
      <h2 class="font-weight-bold text-center dark-grey-text pb-2">
        Communication et Formations internes en entreprise
      </h2>
      <hr class="w-header my-4" />
      <p class="lead text-center pt-2 mb-5">
        Notre application de <strong>formation continue</strong> offre une
        flexibilité inégalée en comparaison des formations ponctuelles
        traditionnelles. L'apprentissage est accessible 24/7, favorisant une
        meilleure rétention des connaissances et une productivité accrue.
      </p>

      <div class="row d-flex justify-content-center px-3">
        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a href="#">Vidéos Courtes et Captivantes</a>
          </h3>
          <p class="small"><strong>Micro Learning</strong></p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="mb-5 pb-2">
            Fini les longs manuels ennuyeux ! Avec you<span
              class="color-primary"
              >can</span
            >learn, l'apprentissage devient rapide et agréable grâce à des
            vidéos qui reprennent les formats des réseaux sociaux (type TikTok,
            FB, Insta ...).
          </p>
        </div>

        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a href="#">Uniformité du Message</a>
          </h3>
          <p class="small">Communication interne</p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="mb-5 pb-2">
            Assurez une cohérence et une qualité de formation inégalées en
            uniformisant le message de votre entreprise à travers l'ensemble de
            vos collaborateurs.
          </p>
        </div>

        <div class="col-md-6 col-xl-4 slide-left">
          <h3 class="font-weight-normal">
            <a href="#">Adaptabilité</a>
          </h3>
          <p class="small">Management, Logistique, Sécurité</p>
        </div>

        <div class="col-md-6 slide-right">
          <p class="mb-5 pb-2">
            Que ce soit pour les métiers d'accueil, l'efficacité commerciale ou
            la sécurité, notre plateforme offre une solution complète et
            adaptable à tous les besoins de
            <strong>formation de votre entreprise</strong>.
          </p>
        </div>
      </div>
    </section>
    <!-- Section -->
  </div>
</template>

<script>
export default {
  name: "AboutService",
};
</script>

<style lang="scss" scoped>
.color-primary {
  color: $color-primary;
}
.about-service {
  color: $color-lighter;
  a {
    color: $color-primary;
  }
}
</style>
