<template>
  <div class="contact">
    <ContactHeader />
    <ContactForm />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import ContactHeader from "@/components/contact/ContactHeader.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
export default {
  components: {
    ContactHeader,
    ContactForm,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Micro Learning pour la Formation en Entreprise",
      description: [
        {
          content:
            "Besoin d’en savoir plus sur nos solutions de micro learning ? Contactez-nous pour découvrir comment YouCanLearn peut améliorer la formation des salariés et la qualité de vie au travail de votre entreprise.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.youcanlearn.fr/contact",
        },
      ],
    });
  },
};
</script>
