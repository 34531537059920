<template>
  <section class="testimonial">
    <div class="container">
      <div class="row">
        <!-- Images des témoignages (indicateurs) à gauche pour les grands écrans -->
        <div class="col-lg-6 d-none d-lg-block">
          <ol class="carousel-indicators tabs">
            <!-- Indicateur Témoignage 1 -->
            <li
              :class="{ active: activeIndex === 0 }"
              @click="setActiveIndex(0)"
            >
              <figure>
                <img
                  src="@/assets/pictures/testimonials-01.webp"
                  class="img-fluid"
                  alt="customer-picture"
                />
              </figure>
            </li>
            <!-- Indicateur Témoignage 2 -->
            <li
              :class="{ active: activeIndex === 1 }"
              @click="setActiveIndex(1)"
            >
              <figure>
                <img
                  src="@/assets/pictures/testimonials-02.webp"
                  class="img-fluid"
                  alt="customer-picture"
                />
              </figure>
            </li>
            <!-- Indicateur Témoignage 3 -->
            <li
              :class="{ active: activeIndex === 2 }"
              @click="setActiveIndex(2)"
            >
              <figure>
                <img
                  src="@/assets/pictures/testimonials-03.webp"
                  class="img-fluid"
                  alt="customer-picture"
                />
              </figure>
            </li>
          </ol>
        </div>

        <!-- Témoignages à droite (carousel) -->
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-interval="false"
          >
            <h2>témoignages</h2>
            <h3>Qu’en pensent les gens ?</h3>
            <div class="carousel-inner">
              <!-- Témoignage 1 statique -->
              <div class="carousel-item" :class="{ active: activeIndex === 0 }">
                <div class="quote-wrapper">
                  <p>
                    "Lorsque j'ai testé l'application je pensais que c'était un
                    réseau social. Mais en fait ce n'est pas du tout ça, c'est
                    vraiment une application de social learning."
                  </p>
                  <h3>Angela – DRH</h3>
                </div>
              </div>

              <!-- Témoignage 2 statique -->
              <div class="carousel-item" :class="{ active: activeIndex === 1 }">
                <div class="quote-wrapper">
                  <p>
                    "Le Micro Social Learning est une manière géniale
                    d'apprendre sans s'en rendre compte."
                  </p>
                  <h3>Rehaan – Responsable Logistique</h3>
                </div>
              </div>

              <!-- Témoignage 3 statique -->
              <div class="carousel-item" :class="{ active: activeIndex === 2 }">
                <div class="quote-wrapper">
                  <p>
                    "Permettre à tous les collaborateurs de mes clients d'avoir
                    accès à un même message, une même ligne directrice c'est
                    magique."
                  </p>
                  <h3>Mickael – Consultant</h3>
                </div>
              </div>
            </div>

            <!-- Indicateurs de pagination en bas du carousel -->
            <ol class="carousel-indicators indicators">
              <li
                :class="{ active: activeIndex === 0 }"
                @click="setActiveIndex(0)"
              ></li>
              <li
                :class="{ active: activeIndex === 1 }"
                @click="setActiveIndex(1)"
              ></li>
              <li
                :class="{ active: activeIndex === 2 }"
                @click="setActiveIndex(2)"
              ></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0, // Index actif pour le changement de témoignage
    };
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index; // Met à jour l'index actif
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}

a,
a:hover {
  text-decoration: none;
}

.testimonial {
  padding: 30px 20px 150px;
}

.row {
  display: flex;
}

.tabs {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  position: relative; /* Make this relative to contain absolute children */
}

.tabs li {
  position: relative;
  cursor: pointer;
}

.tabs li.active::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #feda15;
  border-radius: 50%;
}

.tabs li.active::after {
  content: "";
  position: absolute;
  top: 180px;
  width: 30px;
  height: 30px;
  background-color: #feda15;
  border-radius: 50%;
}

.carousel {
  h2 {
    color: $color-lighter;
    font-size: 20px;
    line-height: 1.45;
    font-weight: 600;
    margin-bottom: 0;
  }

  h3 {
    font-size: 40px;
    color: $color-primary;
    line-height: 1.225;
    margin-top: 23px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .carousel-inner {
    .carousel-item {
      .quote-wrapper {
        margin-top: 42px;
        p {
          font-size: 18px;
          line-height: 1.72222;
          font-weight: 500;
          color: $color-text;
        }
        h3 {
          color: #dfdfdf;
          font-weight: 700;
          margin-top: 37px;
          font-size: 20px;
          line-height: 1.45;
          text-transform: uppercase;
        }
      }
    }
  }

  .carousel-indicators {
    display: flex; /* Use flexbox for layout */
    padding-top: 43px;
    position: relative; /* Ensure this is positioned relative */
    list-style: none;

    li {
      background: #606060;
      height: 6px;
      width: 30px; /* Set a width to make the indicators visible */
      margin: 0 5px; /* Space between indicators */
      cursor: pointer;
    }

    li.active {
      height: 6px;
      background: #feda15;
    }
  }
}
</style>
