<template>
  <div class="container text-center text-white my-5 py-5">
    <h2>Ils nous ont fait confiance</h2>
  </div>
  <div class="slider container">
    <div class="slide-track">
      <div class="slide">
        <img src="@/assets/partners/orange_logo.webp" alt="Orange Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/sncf-connect.webp" alt="SNCF Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/loreal.webp" alt="L'Oréal Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/ysl.webp" alt="YSL Logo" />
      </div>
      <div class="slide">
        <img
          src="@/assets/partners/credit-mutuel.webp"
          alt="Crédit Mutuel Logo"
        />
      </div>
      <div class="slide">
        <img src="@/assets/partners/nescafe.webp" alt="Nescafé Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/penelope.webp" alt="Penelope Logo" />
      </div>
      <div class="slide">
        <img
          src="@/assets/partners/boucheron-paris.webp"
          alt="Boucheron Logo"
        />
      </div>
      <!-- Duplicate the same set of images for seamless scrolling -->
      <div class="slide">
        <img src="@/assets/partners/orange_logo.webp" alt="Orange Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/sncf-connect.webp" alt="SNCF Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/loreal.webp" alt="L'Oréal Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/ysl.webp" alt="YSL Logo" />
      </div>
      <div class="slide">
        <img
          src="@/assets/partners/credit-mutuel.webp"
          alt="Crédit Mutuel Logo"
        />
      </div>
      <div class="slide">
        <img src="@/assets/partners/nescafe.webp" alt="Nescafé Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/penelope.webp" alt="Penelope Logo" />
      </div>
      <div class="slide">
        <img
          src="@/assets/partners/boucheron-paris.webp"
          alt="Boucheron Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselComponent",
};
</script>

<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 8));
  } /* Adjust this depending on the number of images */
}

$animationSpeed: 40s; /* You can adjust this speed for faster or slower scrolling */

.slider {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::before {
    left: 0;
    top: 0;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(
      250px * 16
    ); /* Adjust based on number of slides for smooth looping */
  }

  /*.slide {
    height: 100px;
    width: 250px;
  }*/

  .slide img {
    height: 100px;
    width: auto;
    margin: 0px 10px;
  }
}
</style>
