<template>
  <section class="features">
    <div class="containered">
      <p class="containered-title">
        Vous ne verrez plus jamais la
        <strong>formation en entreprise</strong> du même oeil
      </p>
      <div class="containered">
        <div class="box">
          <span></span>
          <div class="content">
            <h2>Former tous vos collaborateurs</h2>
            <p>
              YCLA a été créé initialement pour les collaborateurs qui n’ont pas
              accès à la formation.
            </p>
          </div>
        </div>
        <div class="box">
          <span></span>
          <div class="content">
            <h2>Augmenter votre Com’ interne</h2>
            <p>
              Transmettez de manière UNIFORME un process, une directive, une
              information.
            </p>
          </div>
        </div>
        <div class="box">
          <span></span>
          <div class="content">
            <h2>Apprendre sans s’en rendre compte</h2>
            <p>
              Montez vos collaborateurs en compétence sans qu’ils ne s’en
              rendent compte.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureSection",
};
</script>

<style lang="scss">
.containered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
}

.containered-title {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 40px;
  color: $color-lighter;
  text-align: center;
  padding: 0px 20px;
  @media (min-width: 992px) {
    font-size: 35px;
    margin-bottom: 80px;
  }
}

.containered .box {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.containered .box::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.containered .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.containered .box:hover:before,
.containered .box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.containered .box:nth-child(1):before,
.containered .box:nth-child(1):after {
  background: linear-gradient(315deg, #a88e47, #feda15);
}

.containered .box:nth-child(2):before,
.containered .box:nth-child(2):after {
  background: linear-gradient(315deg, #a88e47, #feda15);
}

.containered .box:nth-child(3):before,
.containered .box:nth-child(3):after {
  background: linear-gradient(315deg, #a88e47, #feda15);
}

.containered .box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.containered .box span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.containered .box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.containered .box span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.containered .box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.containered .box .content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.containered .box:hover .content {
  left: -25px;
  padding: 60px 40px;
}

.containered .box .content h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.containered .box .content p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
  color: #fff;
}

.containered .box .content a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.containered .box .content a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
</style>
