<template>
  <header class="service-header p-4">
    <h1 class="fall-down">{{ town.title }}</h1>
    <p class="image-fade-in">
      Propulsez votre entreprise vers l'excellence en offrant à vos équipes des
      <strong>formations d'entreprise</strong> continues sur
      {{ town.town }}
    </p>
  </header>
  <TriangleSeparator />
  <main class="container-fluid">
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="text-center">
        <p
          class="font-weight-normal text-center text-uppercase font-small mb-4 fall-down"
        >
          {{ town.town }}
        </p>
        <!-- Section heading -->
        <h2 class="font-weight-bold mb-4 pb-2 fall-down">Le Micro Learning</h2>
        <hr class="w-header" />
        <div class="container mb-5">
          <p class="text-center mx-auto mb-5">
            you<span class="brand-name">can</span>learn est une application de
            <strong>micro learning</strong> innovante conçue pour la
            <strong>formation des salariés</strong> en entreprise. Accessible
            24/7, elle propose des
            <strong>formations entreprise</strong> courtes et personnalisées
            grâce à une IA qui adapte les contenus à chaque poste et suit la
            progression en temps réel. Offrez à vos équipes un
            <strong>apprentissage</strong> flexible, engageant et optimisé pour
            la réussite !
          </p>
        </div>
        <!-- Section description -->
        <p class="lead mx-auto mt-4 mt-lg-5 pt-2 pt-lg-5 my-5">
          quelques un des avantages qu'offre you<span class="brand-name"
            >can</span
          >learn.
        </p>

        <section class="features">
          <div class="container">
            <div class="container">
              <div class="box">
                <span></span>
                <div class="content">
                  <h2>Former tous vos collaborateurs</h2>
                  <p>
                    YCLA a été créé initialement pour les collaborateurs qui
                    n’ont pas accès à la formation.
                  </p>
                </div>
              </div>
              <div class="box">
                <span></span>
                <div class="content">
                  <h2>Augmenter votre Com’ interne</h2>
                  <p>
                    Transmettez de manière UNIFORME un process, une directive,
                    une information.
                  </p>
                </div>
              </div>
              <div class="box">
                <span></span>
                <div class="content">
                  <h2>Apprendre sans s’en rendre compte</h2>
                  <p>
                    Montez vos collaborateurs en compétence sans qu’ils ne s’en
                    rendent compte.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--First row-->
      </section>
    </div>

    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 class="text-center font-weight-bold mb-4">
            La formation en entreprise sur {{ town.town
            }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-au">
            Avec you<span class="brand-name">can</span>learn, le
            <strong>micro learning</strong> devient un atout majeur pour la
            <strong>formation des salariés</strong>. Des contenus ultra-courts
            et ciblés, conçus pour capter l’attention et booster la rétention
            des connaissances, sont accessibles en quelques minutes seulement.
            Offrez à vos équipes une
            <strong>formation entreprise</strong> efficace, motivante et
            parfaitement adaptée à leur rythme !
          </p>
        </div>
        <div class="block-row pt-lg-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Qu’est-ce que le micro learning ?"
              titleColor="#feda15"
            />
            <p class="text-left mx-auto w-responsive">
              Le <strong>micro learning</strong> est une méthode d'apprentissage
              moderne qui se concentre sur la transmission de connaissances en
              petites unités. Contrairement aux formations classiques, les
              contenus de <strong>micro learning</strong> sont présentés sous
              forme de courtes vidéos, d'articles ou d'exercices interactifs. Ce
              format est conçu pour s'intégrer facilement dans le quotidien des
              utilisateurs, en leur permettant de suivre des sessions rapides de
              quelques minutes, n'importe quand et n'importe où. Le
              <strong>micro learning</strong> répond aux besoins d'apprentissage
              des professionnels en offrant un format dynamique et facile à
              consommer.
            </p>
            <ArrowBtn href="/about" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/micro-learning-01.webp"
              alt="mockup-mobile-of-the-learning-app"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title=" Le micro learning pour les formations entreprise"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Le <strong>micro learning</strong> est particulièrement adapté aux
            <strong>formations entreprise</strong> et à la formation des
            salariés. Grâce à des sessions courtes et engageantes, cette méthode
            optimise le temps de formation et favorise une meilleure rétention
            des connaissances. Les collaborateurs peuvent se former à leur
            propre rythme, en intégrant l'apprentissage dans leur journée de
            travail. Le <strong>micro learning</strong> est également idéal pour
            maintenir les compétences à jour, en proposant des contenus
            récurrents et facilement accessibles. Ainsi, il contribue à
            renforcer l'efficacité des
            <strong>formations entreprise</strong> tout en soutenant le
            développement continu des équipes.
          </p>
          <ArrowBtn
            href="/about"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/micro-learning.webp"
              alt="iphone-mockup-of-the-app"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--DEVWEB-->
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 id="developpement-web" class="text-center font-weight-bold mb-4">
            {{ town.town }}<span class="span-ml">:</span> Qualité de vie au
            travail
          </h2>
          <p class="text-center mx-auto mb-5">
            Avec you<span class="brand-name">can</span>learn, améliorez la
            <strong>qualité de vie au travail</strong> en offrant à vos
            collaborateurs des
            <strong>formations entreprise</strong> stimulantes et accessibles
            24/7. Les contenus ciblés favorisent l’épanouissement et la montée
            en compétences, tout en réduisant le stress lié à la
            <strong>formation des salariés</strong>. Boostez la
            <strong>qvt au travail</strong> en transformant la formation en un
            moment de plaisir et de développement personnel !
          </p>
        </div>
        <div class="block-row pt-lg-3">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Comprendre la qualité de vie au travail"
              titleColor="#feda15"
            />
            <p class="text-left mx-auto w-responsive">
              La <strong>qualité de vie au travail</strong> (QVT) désigne
              l'ensemble des conditions qui contribuent au bien-être des
              salariés dans leur environnement professionnel. Elle inclut des
              éléments variés comme l'équilibre entre vie professionnelle et
              personnelle, la sécurité, la santé au travail, et la
              reconnaissance des efforts. Améliorer la
              <strong>qualité de vie au travail</strong> favorise la motivation
              des employés et renforce leur engagement. Avec you<span
                class="brand-name"
                >can</span
              >learn, les entreprises peuvent intégrer des formations courtes et
              dynamiques qui soutiennent la <strong>qvt au travail</strong>, en
              offrant à leurs collaborateurs des contenus enrichissants et des
              conseils pratiques accessibles à tout moment.
            </p>
            <ArrowBtn href="/" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/qvt-01.webp"
              alt="three-worker-happy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-2">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Promouvoir la qualité de vie au travail sans attendre la semaine de la QVT"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            La <strong>semaine de la QVT</strong> est un événement annuel
            destiné à sensibiliser les organisations sur l'importance de la
            <strong>qualité de vie au travail</strong>. Durant cette
            <strong>semaine QVT</strong>, des actions concrètes sont mises en
            place pour favoriser le bien-être des salariés : ateliers de gestion
            du stress, activités de team-building, aménagements des espaces de
            travail, et bien plus encore. Grâce à l’application you<span
              class="brand-name"
              >can</span
            >learn, les entreprises peuvent prolonger les effets positifs de la
            <strong>semaine de la QVT</strong> en proposant des formations
            continues sur la <strong>qualité de vie au travail</strong>,
            permettant ainsi à leurs collaborateurs de renforcer leur bien-être
            au quotidien.
          </p>
          <ArrowBtn
            href="/"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/qvt-02.webp"
              alt="people-doing-their-job"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <section class="container mt-lg-5">
      <div class="block-row pt-lg-2 pb-5 mb-5">
        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-left">
          <!-- Featured image -->

          <!-- Section description -->
          <TitleColor
            title="Intégrer la formation des salariés dans une stratégie de qualité de vie au travail"
            titleColor="hsl(180, 62%, 55%)"
            bgColor="hsl(180, 62%, 55%)"
          />
          <p class="text-left mx-auto w-responsive">
            La <strong>formation des salariés</strong> joue un rôle central dans
            l'amélioration de la <strong>qualité de vie au travail</strong>. En
            offrant des opportunités d'apprentissage continu, les entreprises
            valorisent les compétences de leurs collaborateurs tout en
            contribuant à leur épanouissement professionnel. Chez you<span
              class="brand-name"
              >can</span
            >learn, nous croyons que des programmes de formation accessibles
            24/7 permettent aux salariés de se développer à leur rythme,
            réduisant ainsi le stress lié à l'acquisition de nouvelles
            compétences. Intégrer le <strong>micro learning</strong> dans la
            stratégie de <strong>qvt au travail</strong> renforce la motivation,
            améliore la productivité et participe pleinement à la réussite de la
            <strong>formation des salariés</strong>.
          </p>
          <ArrowBtn
            href="/"
            whiteCircleBackground="hsl(180, 62%, 45%)"
            circleBoxShadow="0 0 2px 2px hsl(180, 62%, 45%)"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-12 col-lg-5 pt-lg-5 img-double slide-right">
          <img
            src="@/assets/pictures/qvt-03.webp"
            alt="ia-demonstration"
            class="img-fluid"
          />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <!-- Grid row -->
      <ContactUsAction />
      <!-- Grid row -->
    </section>
    <!--/DEVWEB-->
    <!--SEO/SEA-->
    <div class="container my-5">
      <!--Section: Content-->
      <section class="">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5">
          <h2
            id="referencement-google"
            class="text-center font-weight-bold mb-4"
          >
            Des formations entreprises adaptées à chaque poste avec you<span
              class="brand-name"
              >can</span
            >learn
          </h2>
          <p class="text-center mx-auto">
            Grâce à l’IA de <strong>YouCanLearn</strong>, proposez des
            <strong>formations continues</strong> parfaitement adaptées aux
            besoins spécifiques de chaque poste : management, logistique,
            métiers d’accueil, sécurité incendie, et bien plus. En ciblant les
            lacunes et en ajustant les contenus pour chaque collaborateur, notre
            solution garantit une montée en compétences efficace et sur-mesure.
            Offrez à vos équipes des
            <strong>formations entreprise</strong> personnalisées, motivantes et
            optimisées pour développer les talents à tous les niveaux !
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="La formation entreprise adaptée à chaque poste grâce à l’IA"
              titleColor="#feda15"
            />
            <p class="text-left mx-auto w-responsive">
              Avec you<span class="brand-name">can</span>learn, nous
              révolutionnons la <strong>formation entreprise</strong> en
              personnalisant les contenus pour chaque poste grâce à notre
              intelligence artificielle. Que vos équipes travaillent dans la
              logistique, le management, le commerce, ou soient en charge de la
              sécurité incendie, notre IA analyse les besoins spécifiques de
              chaque collaborateur pour proposer des modules de
              <strong>formation des salariés</strong> parfaitement ajustés. Cela
              permet de cibler les compétences clés requises pour chaque métier,
              comme la prise de parole active pour les managers ou la gestion de
              caisse pour les caissiers. Grâce à cette approche, chaque
              collaborateur bénéficie d’un parcours de formation sur-mesure,
              favorisant ainsi son développement et son efficacité au quotidien.
            </p>
            <ArrowBtn href="/" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/formation-ia.webp"
              alt="circle-with-four-picture-of-people-use-ia"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Optimiser la formation des salariés avec le suivi et le monitoring"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            L’IA de you<span class="brand-name">can</span>learn ne se contente
            pas de proposer des formations : elle évalue en continu les
            résultats obtenus et identifie les lacunes. Grâce au monitoring
            précis des performances, l’application détecte les points de
            blocage, ajuste les modules, et suggère des formations pour
            améliorer le taux de réussite aux certifications internes. Avec
            cette analyse fine, la <strong>formation entreprise</strong> devient
            un levier stratégique, garantissant une montée en compétence
            progressive et continue. Les responsables peuvent suivre
            l’apprentissage des équipes en temps réel, ce qui facilite la
            gestion des plans de formation et assure un retour sur
            investissement optimal pour l’entreprise.
          </p>
          <ArrowBtn
            href="/"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/multi-work.webp"
              alt="graphic-economy-energy"
              class="many-picture-of-different-job"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--/SEASEO-->
    <!--MARKETING-->
    <div class="container mt-5">
      <!--Section: Content-->
      <section class="">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5 mt-5">
          <h2 id="marketing-digital" class="text-center font-weight-bold mb-4">
            {{ town.content }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-auto mb-lg-5">
            Fondée par des passionnés d’improvisation issus du
            <strong>Jamel Comedy Club</strong>,
            <strong>IMPROVISACTION</strong> a su marier humour et pédagogie pour
            créer des formations uniques et impactantes. En lançant you<span
              class="brand-name"
              >can</span
            >learn, ils apportent cet esprit créatif et novateur à la
            <strong>formation entreprise</strong>. Grâce à leur expérience
            scénique, nos contenus dynamisent la
            <strong>formation des salariés</strong> en intégrant des approches
            ludiques et interactives qui captivent l’attention tout en boostant
            les <strong>soft skills</strong> des collaborateurs. Une méthode qui
            combine performance, développement personnel et plaisir d’apprendre
            !
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor title="improvisaction" titleColor="#feda15" />
            <p class="text-left mx-auto w-responsive">
              You<span class="brand-name">can</span>learn est le fruit de
              l’expertise et de la vision d’<strong>IMPROVISACTION</strong>, une
              structure créée par des passionnés réunissant comédiens, DRH et
              formateurs. Alliant humour, pédagogie, et innovation,
              <strong>IMPROVISACTION</strong> a pour mission d’améliorer la
              communication et la <strong>qualité de vie au travail</strong> en
              entreprise. Avec You<span class="brand-name">can</span>learn,
              l’équipe va encore plus loin en réinventant la
              <strong>formation entreprise</strong> à travers le
              <strong>micro learning</strong>. L’application est conçue pour
              offrir une <strong>formation des salariés</strong> adaptée et
              ciblée, grâce à une intelligence artificielle qui analyse les
              besoins et propose des contenus personnalisés selon les
              spécificités de chaque poste (management, logistique, métiers
              d’accueil, sécurité incendie, et bien plus). you<span
                class="brand-name"
                >can</span
              >learn intègre ainsi l’approche dynamique et créative d’<strong
                >IMPROVISACTION</strong
              >
              pour offrir une <strong>formation des salariés</strong> qui
              combine rigueur et engagement, tout en permettant de suivre
              l’évolution des compétences en temps réel. Le résultat : une
              <strong>formation entreprise</strong> sur-mesure, interactive, et
              en phase avec l’état d’esprit d’<strong>IMPROVISACTION</strong> —
              mêlant performance, bien-être et montée en compétences.
            </p>
            <ArrowBtn href="/contact" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/brand/ycla-rounded-white.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->

    <!--Section: Content-->
    <!--/Marketing-->
    <div class="container service-contact image-fade-in">
      <CallActionService />
    </div>
  </main>
</template>

<script>
import { useMeta } from "vue-meta";
import { toRef, watchEffect } from "vue";
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import TitleColor from "@/components/partials/TitleColor.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";

export default {
  components: {
    TriangleSeparator,
    ArrowBtn,
    TitleColor,
    ContactUsAction,
    CallActionService,
  },
  name: "TownRead",
  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const townProp = toRef(props, "town");

    watchEffect(() => {
      if (townProp.value) {
        const sentenceBegin = "Rencontrez nous au ";
        const sentenceEnd =
          " pour découvrir comment YouCanLearn révolutionne la formation des salariés avec le micro learning. Nos solutions boostent la qualité de vie au travail et la qvt grâce à des formations courtes et engageantes adaptées à chaque poste.";

        const metaDescription =
          sentenceBegin + townProp.value.content + sentenceEnd;

        useMeta({
          title: townProp.value.title,
          meta: [
            {
              name: "description",
              content: metaDescription,
            },
          ],
          link: [
            {
              rel: "canonical",
              href:
                "https://www.youcanlearn.fr/ma-ville/" + townProp.value.slug,
            },
          ],
        });
      }
    });

    return {
      townData: townProp, // Utiliser un nom unique pour la clé retournée
    };
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      TownDataFromStore: "StateTown", // Renommer la clé pour éviter la duplication
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p,
h2,
h3,
h4 {
  color: $color-lighter;
}
.brand-name {
  color: $color-primary;
}
.features {
  & .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
  }

  & .container-title {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 40px;
    color: $color-lighter;
    text-align: center;
    @media (min-width: 992px) {
      font-size: 51px;
      margin-bottom: 80px;
    }
  }

  & .container .box {
    position: relative;
    width: 320px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 30px;
    transition: 0.5s;
  }

  & .container .box::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    text-decoration: none;
    background: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
  }

  & .container .box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50;
    width: 50%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
  }

  & .container .box:hover:before,
  .container .box:hover:after {
    transform: skewX(0deg);
    left: 20px;
    width: calc(100% - 90px);
  }

  & .container .box:nth-child(1):before,
  .container .box:nth-child(1):after {
    background: linear-gradient(315deg, #a88e47, #feda15);
  }

  & .container .box:nth-child(2):before,
  .container .box:nth-child(2):after {
    background: linear-gradient(315deg, #03a9f4, #ba02caa3);
  }

  & .container .box:nth-child(3):before,
  .container .box:nth-child(3):after {
    background: linear-gradient(315deg, #a88e47, #feda15);
  }

  & .container .box span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
  }

  & .container .box span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.1s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  & .container .box:hover span::before {
    top: -50px;
    left: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
  }

  & .container .box span::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    animation-delay: -1s;
  }

  & .container .box:hover span:after {
    bottom: -50px;
    right: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
  }

  @keyframes animate {
    0%,
    100% {
      transform: translateY(10px);
    }

    50% {
      transform: translate(-10px);
    }
  }

  & .container .box .content {
    position: relative;
    left: 0;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1;
    transform: 0.5s;
    color: #fff;
  }

  & .container .box:hover .content {
    left: -25px;
    padding: 60px 40px;
  }

  & .container .box .content h2 {
    font-size: 2em;
    color: #fff;
    margin-bottom: 10px;
  }

  & .container .box .content p {
    font-size: 1.1em;
    margin-bottom: 10px;
    line-height: 1.4em;
    color: #fff;
  }

  & .container .box .content a {
    display: inline-block;
    font-size: 1.1em;
    color: #111;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    margin-top: 5px;
  }

  & .container .box .content a:hover {
    background: #ffcf4d;
    border: 1px solid rgba(255, 0, 88, 0.4);
    box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
  }
}
.service-header {
  height: 50vh;
  background-image: url("@/assets/pictures/ia-learn.webp");
  background-size: contain;
  /*@media screen and (min-width: 992px) {
    background-size: cover;
  }*/
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h1 {
    color: $color-primary;
    font-size: 3em;
    text-align: center;
  }
  & p {
    color: $color-lighter;
    font-size: 1.3em;
    text-align: center;
    & .fake-space {
      margin-left: 8px;
    }
  }
}
.card-service:hover {
  @include grow;
}
.service-card {
  @include thumbnail;
  height: 100%;
  padding: 15px 15px 15px;
  & .card-body {
    padding: 0px;
    & h4 {
      font-size: 1.3em;
      margin: 15px 5px 0px;
      color: $color-text-grey;
    }
  }
}
.block-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  & video,
  img {
    width: 500px;
    max-width: 90vw;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
      width: 500px;
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
  & .img-double {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .hr {
    width: 50px;
    height: 3px;
    background-color: $background;
  }
}

.reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse !important;
  }
}
.ink {
  width: 200px;
  height: 200px;
  background-color: #000;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 150px;
    background-color: #000;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &::before {
    top: -30px;
    left: 50px;
  }

  &::after {
    bottom: -60px;
    right: 50px;
  }
}
.service-contact {
  margin-top: 100px;
  margin-bottom: 200px;
  @media screen and (min-width: 992px) {
    margin-top: 200px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $color-lighter;
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
.cyan {
  border-top: 1.5px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 1.5px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 1.5px solid $color-primary;
}
.yellow {
  border-top: 1.5px solid hsl(59, 91%, 65%);
}
.span-ml {
  margin-left: 4px;
}
</style>
