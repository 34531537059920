<template>
  <div class="container-fluid">
    <div class="wrapper">
      <div class="typing-demo">Les News du Micro Social Learning</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "textHiting",
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  place-items: center;
  padding: 10px; /* Ajouté un peu de padding pour éviter que le texte touche les bords */
}

.typing-demo {
  width: 36ch; /* Largeur définie pour les grandes résolutions */
  animation: typing 4s steps(36), blink 0.75s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1.8em;
  color: $color-lighter;
}

/* Media query pour les petites résolutions */
@media (max-width: 860px) {
  .typing-demo {
    animation: none; /* Supprimer l'animation sur mobile */
    width: 100%; /* Permet au texte de prendre toute la largeur disponible */
    white-space: normal; /* Permet le retour à la ligne sur mobile */
    border-right: none; /* Supprimer le curseur clignotant */
    font-size: 1.8em; /* Augmenter la taille du texte sur mobile */
    text-align: center; /* Centrer le texte */
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 36ch;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>
