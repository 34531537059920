<template>
  <div class="container service-contact">
    <div class="row mb-3">
      <div class="col-md-4 mx-auto">
        <div class="soyuz">
          <div class="view rocket mb-lg-4 pb-2">
            <img
              src="@/assets/brand/ycl.webp"
              class="img-fluid"
              alt="smaple image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="service-contact__text">
      <h3 class="text-center mb-4 pb-2">
        Une formation des salariés 24/7 avec
        <span class="brand-name">ycla</span>
      </h3>

      <p class="text-center mx-auto mb-4 pb-2">
        Sentez vous libre de nous contactez pour toute question concernant vos
        besoin de
        <strong>formation d'entreprise</strong>, notre équipe vous apportera les
        réponses adaptées à votre activité.
      </p>

      <a :href="linkBtnFooter" class="btn btn-secondary btn-rounded btn-md"
        ><i class="fas fa-clone left"></i>
        <strong>Digitaliser mon entreprise</strong></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CallActionFooter",
  props: {
    linkBtnFooter: {
      type: String,
      default: "/contact", // Couleur par défaut si la prop n'est pas fournie
    },
  },
  mounted() {
    this.startRocket();
  },
  methods: {
    startRocket() {
      const launcher = document.querySelector(".soyuz");

      // Déclencher l'animation toutes les 5 secondes
      setInterval(() => {
        // Ajouter une classe pour déclencher l'animation
        launcher.classList.add("launcher");

        // Retirer la classe après 5 secondes pour arrêter l'animation
        setTimeout(() => {
          launcher.classList.remove("launcher");
        }, 2000); // Durée de l'animation en millisecondes (5 secondes)
      }, 3000); // Intervalle entre chaque animation en millisecondes (10 secondes)
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-name {
  color: $color-primary;
}
.service-contact {
  margin-top: 100px;
  margin-bottom: 200px;
  @media screen and (min-width: 992px) {
    margin-top: 200px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $color-text;
    }
    & p {
      color: #fedb15a6;
    }
    & a {
      background: $color-darker;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
@keyframes tremble {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2px, 2px);
  }
  50% {
    transform: translate(2px, -2px);
  }
  75% {
    transform: translate(-2px, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.service-contact:hover {
  & .rocket {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    //animation: tremble 0.5s infinite;
  }
}
.launcher {
  @media screen and (max-width: 992px) {
    & .rocket {
      cursor: pointer;
      // animation: tremble 0.5s 3;
    }
  }
}
</style>
