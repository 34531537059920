<template>
  <div class="Header">
    <div class="Header-background"></div>
    <div class="Header-content">
      <div class="Header-hero">
        <h1>
          <span class="yellow">APPRENDRE</span><br />
          n'a jamais été aussi simple
        </h1>
        <p>
          Découvrez toutes les possibilités offertes par notre application de
          <strong>micro social learning</strong>.
        </p>
        <button @click="handleDiscovery" class="Button">Découvrir</button>
      </div>
      <div class="Header-visuals">
        <div class="Iphone">
          <video
            src="@/assets/pictures/jc-presente.mp4"
            class="Iphone-image"
            autoplay
            loop
            muted
          ></video>
          <!---<img
            src="@/assets/pictures/ycla-iphone.webp"
            alt="Aperçu de l'application"
            class="Iphone-image"
          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "HeaderComponent",
  methods: {
    handleDiscovery() {
      router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Varela+Round");

/*:root {
  --color-1: #21d4fd;
  --color-2: #b721ff;
  --color-3: #08aeea;
  --color-4: #2af598;
}*/

.Header {
  display: block;

  &-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
    transform: skewY(-10deg);
    overflow: hidden;
  }

  &-content {
    padding: 4rem 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 992px) {
      text-align: left;
      padding: 10rem 4rem;
      @media (min-width: 992px) {
        padding: 10rem 4rem 5rem;
      }
      max-width: 73rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: unset;
    }
  }

  &-hero {
    /*background-image: url("@/assets/pictures/ia-girl.webp");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;*/
    h1,
    p {
      color: white;
      text-shadow: 0 0.5rem 1rem rgba(50, 0, 100, 0.1);
    }

    h1 {
      margin: 0;
      font-size: 3rem;
      & span {
        color: $color-primary;
      }
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  &-visuals {
    margin-top: 5rem;
    transform: rotate(-10deg);

    @media (min-width: 992px) {
      margin: 0;
      transform: translateY(-5rem) rotate(-10deg);
    }
  }

  .Button {
    padding: 5px 20px;
    border: 0;
    color: $color-darker;
    font-size: 1.2rem;
    font-weight: bold;
    background: $color-primary;
    border-radius: 10px;
    transition: all 0.2s;
    cursor: pointer;
    box-shadow: 0 1.75rem 2rem -0.4rem rgba(50, 0, 100, 0.15);

    &:hover {
      color: var(--color-4);
      box-shadow: 0 1.75rem 3rem 0rem rgba(50, 0, 100, 0.1);
      transform: scale(1.05);
    }

    &:active {
      box-shadow: 0 1.75rem 2.5rem -0.2rem rgba(50, 0, 100, 0.125);
      transform: scale(1.025);
    }
  }

  .Iphone {
    display: inline-block;
    background: white;
    padding: 4rem 0.6rem 0;
    border-radius: 2rem;
    box-shadow: -1rem 1.75rem 2rem -0.4rem rgba(50, 0, 100, 0.1),
      -0.2rem 0.2rem 0.5rem rgba(50, 0, 100, 0.05),
      inset 0.5rem -0.5rem 0.5rem -0.4rem rgba(50, 0, 100, 0.1);
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      background-color: var(--color-3);
      background-image: linear-gradient(
        0deg,
        var(--color-3) 0%,
        var(--color-4) 100%
      );
      width: 16rem;
      padding-top: 177.5%;
    }

    &::after {
      display: inline-block;
      margin: 0.6rem;
      padding: 1.4rem;
      border: 1px solid #595959;
      border-radius: 2rem;
      box-shadow: inset -0.05rem 0.05rem 0.5rem 0rem rgba(50, 0, 100, 0.05);
    }
  }

  .Iphone-image {
    position: absolute;
    top: 4%;
    left: 2.5%;
    width: 95%;
    height: 84%;
    border-radius: 20px 20px 5px 5px;
  }
}
</style>
