<template>
  <footer id="dk-footer" class="dk-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="dk-footer-box-info">
            <a href="index.html" class="footer-logo">
              <img
                src="@/assets/brand/ycl.webp"
                alt="footer_logo"
                width="200px"
                class="img-fluid"
              />
            </a>
            <p class="footer-info-text">
              YouCanLearn est une application de Micro Social Learning conçue
              pour répondre aux besoins de communication et de formation des
              entreprises.
            </p>
            <div class="footer-social-link">
              <h3>Follow us</h3>
              <ul>
                <li>
                  <a href="#">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://x.com/YCLA_france">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@youcanlearn.app?lang=en">
                    <i class="fab fa-tiktok"></i>
                  </a>
                </li>
                <li>
                  <a href="https://fr.linkedin.com/company/you-can-learn-app">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- End Social link -->
          </div>
          <!-- End Footer info -->
        </div>
        <!-- End Col -->
        <div class="col-12 col-md-12 col-lg-8">
          <div class="row-contact mt-5 pt-5 mt-lg-0 pt-lg-0">
            <div class="col-10 col-md-6 mx-auto">
              <div class="contact-us">
                <div class="contact-icon">
                  <i class="fa fa-map-o" aria-hidden="true"></i>
                </div>
                <!-- End contact Icon -->
                <div class="contact-info">
                  <h3>Paris, France</h3>
                  <p>37 rue des Mathurins, 75008</p>
                </div>
                <!-- End Contact Info -->
              </div>
              <!-- End Contact Us -->
            </div>
            <!-- End Col -->
            <div class="col-10 col-md-6 mx-auto">
              <div class="contact-us contact-us-last">
                <div class="contact-icon">
                  <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                </div>
                <!-- End contact Icon -->
                <div class="contact-info">
                  <h3>06.98.54.35.86</h3>
                  <p>Besoin d'une démo ?</p>
                </div>
                <!-- End Contact Info -->
              </div>
              <!-- End Contact Us -->
            </div>
            <!-- End Col -->
          </div>
          <!-- End Contact Row -->
          <div class="row">
            <div class="col-10 col-lg-6 mx-auto">
              <div class="footer-widget footer-left-widget">
                <div class="section-heading">
                  <h3>Liens utiles</h3>
                  <span class="animate-border border-black"></span>
                </div>
                <ul>
                  <li>
                    <a href="/about">À propos</a>
                  </li>
                  <li>
                    <a href="/about">Services</a>
                  </li>
                  <li>
                    <a
                      href="https://drive.google.com/file/d/1RkIxky9PNRSZEi8OcGEIkNOqcm34cvHr/view"
                      >Lire PDF</a
                    >
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="/">Accueil</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contact">contact</a>
                  </li>
                </ul>
              </div>
              <!-- End Footer Widget -->
            </div>
            <!-- End col -->
            <div class="col-md-12 col-lg-6">
              <div class="footer-widget">
                <div class="section-heading">
                  <h3>Live Demo</h3>
                  <span class="animate-border border-black"></span>
                </div>
                <p>
                  Notre équipe vous proposera différentes dates pour une
                  démonstration dans votre entreprise.
                </p>
                <form ref="formData" @submit.prevent="sendEmail">
                  <div class="">
                    <input
                      type="hidden"
                      name="reason"
                      value="Demande pour une démo live"
                      required
                    />
                  </div>
                  <div class="form-row">
                    <div class="col dk-footer-form">
                      <input
                        type="text"
                        id="form-email"
                        name="form-email"
                        placeholder="Adresse mail"
                        required
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        class="form-control"
                      />
                      <button type="submit">
                        <i class="fa fa-send"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-center mt-2">
                    <span v-if="this.isCaptchaVisible">
                      <vue-hcaptcha
                        sitekey="e35a3d02-8d4c-44a8-b280-a3445796eb2e"
                        @verify="onVerify"
                        @error="onError"
                      ></vue-hcaptcha>
                    </span>
                  </div>
                </form>
                <span v-if="this.modalConfirm">
                  <ConfirmModal
                    @closeModalConfirm="closeModalConfirm"
                    title="Merci pour votre confiance !"
                    message="Vous allez être recontactés dans les plus brefs délais"
                    btnExit="À bientôt"
                  />
                </span>
                <!-- End form -->
              </div>
              <!-- End footer widget -->
            </div>
            <!-- End Col -->
          </div>
          <!-- End Row -->
        </div>
        <!-- End Col -->
      </div>
      <!-- End Widget Row -->
    </div>
    <!-- End Contact Container -->

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <a href="https://www.improvisaction.com/" class="improvisaction"
              >Copyright © 2024, All Right Reserved improvisaction</a
            >
            <div class="malaweb">
              <a href="https://malaweb.fr/">Powered by Malaweb</a>
            </div>
          </div>
        </div>
        <!-- End Row -->
      </div>
      <!-- End Copyright Container -->
    </div>
    <!-- End Copyright -->
    <!-- Back to top -->
    <div id="back-to-top" class="back-to-top">
      <button
        @click="handleAnchor"
        class="btn btn-dark"
        title="Back to Top"
        style="display: block"
      >
        <i class="fa fa-angle-up"></i>
      </button>
    </div>
    <!-- End Back to top -->
  </footer>
</template>

<script>
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  name: "FooterBase",
  components: { VueHcaptcha, ConfirmModal },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
      showOrder: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendCallMeMail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendCallMeMail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
      this.showOrder = false;
    },
    showModalOrder() {
      this.showOrder = true;
    },
    closeModalOrder() {
      this.$emit("closeModalOrder");
    },
    handleAnchor() {
      router.push({ hash: "#" });
    },
  },
};
</script>

<style scoped lang="scss">
.footer-widget p {
  margin-bottom: 27px;
}
p {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: white;
  line-height: 28px;
}

.animate-border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: $color-primary;
}

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #000000;
  border-right: 10px solid #000000;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

.animate-border.border-white:after {
  border-color: #fff;
}

.animate-border.border-yellow:after {
  border-color: #f5b02e;
}

.animate-border.border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff;
}

.animate-border.border-ash:after {
  border-right-color: #eef0ef;
  border-left-color: #eef0ef;
}

.animate-border.border-offwhite:after {
  border-right-color: #f7f9f8;
  border-left-color: #f7f9f8;
}

/* Animated heading border */
@keyframes primary-short {
  0% {
    width: 15%;
  }
  50% {
    width: 90%;
  }
  100% {
    width: 10%;
  }
}

@keyframes primary-long {
  0% {
    width: 80%;
  }
  50% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

.dk-footer {
  padding: 75px 0 0;
  background-color: $background;
  position: relative;
  z-index: 2;
}
.dk-footer .contact-us {
  margin-top: 0;
  margin-bottom: 30px;
  @media screen and (min-width: 992px) {
    padding-left: 80px;
  }
}
/*.dk-footer .contact-us .contact-info {
  margin-left: 50px;
}
.dk-footer .contact-us.contact-us-last {
  margin-left: -80px;
}*/
.dk-footer .contact-icon i {
  font-size: 24px;
  top: -15px;
  position: relative;
  color: $color-primary;
}

.dk-footer-box-info {
  top: -122px;
  background: $color-primary;
  padding: 40px;
  z-index: 2;
  & .footer-logo {
    background-color: #000;
    padding: 8px 16px 16px;
    border-radius: 13px;
  }
}
.dk-footer-box-info .footer-social-link h3 {
  color: $color-darker;
  font-size: 24px;
  margin-bottom: 25px;
}
.dk-footer-box-info .footer-social-link ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dk-footer-box-info .footer-social-link li {
  display: inline-block;
}
.dk-footer-box-info .footer-social-link a i {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background: #000;
  margin-right: 5px;
  color: #fff;
}
.dk-footer-box-info .footer-social-link a i.fa-facebook {
  background-color: #3b5998;
}
.dk-footer-box-info .footer-social-link a i.fa-twitter {
  background-color: #08bddd;
}
.dk-footer-box-info .footer-social-link a i.fa-tiktok {
  background-color: #000000;
}
.dk-footer-box-info .footer-social-link a i.fa-linkedin {
  background-color: #0976b4;
}
.dk-footer-box-info .footer-social-link a i.fa-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #000 0,
    #c6b400 5%,
    #fd5949 40%,
    #d6249f 60%,
    #285aeb 95%
  );
}

.footer-info-text {
  margin: 26px 0 32px;
  color: $color-darker;
}

.footer-left-widget {
  margin-bottom: 100px;
  @media screen and (min-width: 992px) {
    padding-left: 80px;
  }
}

.footer-widget .section-heading {
  margin-bottom: 35px;
}

.footer-widget h3 {
  font-size: 24px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.footer-widget ul {
  width: 50%;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-widget li {
  margin-bottom: 18px;
}

.footer-widget p {
  margin-bottom: 27px;
}

.footer-widget a {
  color: #878787;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.footer-widget a:hover {
  color: #007bff;
}

.footer-widget:after {
  content: "";
  display: block;
  clear: both;
}

.dk-footer-form {
  position: relative;
}
.dk-footer-form input[type="email"] {
  padding: 14px 28px;
  border-radius: 50px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
}
.dk-footer-form input::-webkit-input-placeholder,
.dk-footer-form input::-moz-placeholder,
.dk-footer-form input:-ms-input-placeholder,
.dk-footer-form input::-ms-input-placeholder,
.dk-footer-form input::-webkit-input-placeholder {
  color: #878787;
  font-size: 14px;
}
.dk-footer-form input::-webkit-input-placeholder,
.dk-footer-form input::-moz-placeholder,
.dk-footer-form input:-ms-input-placeholder,
.dk-footer-form input::-ms-input-placeholder,
.dk-footer-form input::placeholder {
  color: #878787;
  font-size: 14px;
}
.dk-footer-form button[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 24px 6px 17px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid $color-primary;
  background: $color-primary;
  color: $color-darker;
}
.dk-footer-form button:hover {
  cursor: pointer;
}

.row-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.form-control {
  width: 93%;
}
/* ==========================

    Contact

=============================*/
.contact-us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-icon {
  position: absolute;
}
.contact-icon i {
  font-size: 36px;
  top: -5px;
  position: relative;
  color: #007bff;
}

.contact-info {
  margin-left: 50px;
  color: #fff;
}
.contact-info h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}

.copyright {
  padding: 20px 0;
  margin-top: 55px;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyright span,
.copyright a {
  color: #878787;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.copyright a:hover {
  color: #007bff;
}

.copyright-menu ul {
  text-align: right;
  margin: 0;
}

.copyright-menu li {
  display: inline-block;
  padding-left: 20px;
}

.back-to-top {
  position: relative;
  z-index: 2;
}
.back-to-top .btn-dark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2e2e2e;
  border-color: #2e2e2e;
  display: none;
  z-index: 999;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.back-to-top .btn-dark:hover {
  cursor: pointer;
  background: #feda15;
  border-color: #feda15;
  & i {
    color: $color-darker;
  }
}
.malaweb {
  &:hover {
    & a {
      color: #f27316;
    }
  }
}
.improvisaction {
  &:hover {
    color: $color-primary;
  }
}
</style>
