<template>
  <div class="container py-5 my-5">
    <p class="team text-center mb-4 py-5 lead font-weight-bold">
      La TEAM you<span class="color-primary">can</span>learn
    </p>
    <div class="row justify-content-center">
      <!-- Première carte -->
      <div class="col-md-4 card-column d-flex">
        <div class="card d-flex">
          <div class="card-image">
            <img
              class="img-fluid d-flex mx-auto"
              src="@/assets/malateam/sam.webp"
              alt="a-team-member"
            />
          </div>
          <div class="card-text">
            <div class="card-title">SAM</div>
            Samir, ancien comédien et acteur. Il a participé à de nombreux
            projets, il est actuellement le Président et Directeur Commercial
            d’Improvisaction mais également le producteur de la troupe
            d’improvisation du Jamel Comedy Club.
          </div>
          <div class="footer">
            <span id="name">Samir Benhaj<br /></span>
            <span id="position"
              >Président de
              <a href="https://www.improvisaction.com/">Improvisaction</a></span
            >
          </div>
        </div>
      </div>

      <!-- Deuxième carte -->
      <div class="col-md-4 card-column d-flex">
        <div class="card exept d-flex">
          <div class="card-image">
            <img
              class="img-fluid d-flex mx-auto"
              src="@/assets/malateam/jc.webp"
              alt="a-team-member"
            />
          </div>
          <div class="card-text">
            <div class="card-title">JC</div>
            JC est le leader de la troupe d’improvisation du Jamel Comedy Club.
            Artiste très expérimenté; il intervient auprès des Exec et Comex
            pour les accompagner sur la prise de parole en public. Il est
            également directeur associé de improvisaction.
          </div>
          <div class="footer">
            <span id="name">Jean Charles Mulier<br /></span>
            <span id="position"
              >Comédien/formateur
              <a
                href="https://lecomedyclub.com/evenements/les-zinde-impro-comedy-club/"
                >Les Zindé</a
              ></span
            >
          </div>
        </div>
      </div>

      <!-- Troisième carte -->
      <div class="col-md-4 card-column d-flex">
        <div class="card d-flex">
          <div class="card-image">
            <img
              class="img-fluid d-flex mx-auto"
              src="@/assets/malateam/mt.webp"
              alt="a-team-member"
            />
          </div>
          <div class="card-text">
            <div class="card-title">MT</div>
            Mehdi, est un expert dans les domaines de la transformation digitale
            et de la formation. Ancien Coach Agile, entrepreneur et
            investisseur, il est en charge du pilotage des développements IA et
            mobile. Il dispose d'une équipe pluridisciplinaire capable de
            développer toute solution digitale pour les entreprises.
          </div>
          <div class="footer">
            <span id="name">Mehdi Terbeche<br /></span>
            <span id="position"
              >Entrepreneur/Investisseur
              <a href="https://fr.linkedin.com/in/mehdi-terbeche-9528721a"
                >LinkedIn</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutTeam",
};
</script>

<style lang="scss" scoped>
.team {
  color: $color-lighter;
  font-size: 40px;
}
.color-primary {
  color: $color-primary;
}
.container {
  max-width: 950px;
}

.card-column {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrage des cartes verticalement */
  align-items: center; /* Centrage des cartes horizontalement */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* La carte prend 100% de la hauteur de la colonne */
  width: 100%; /* Prend toute la largeur de la colonne */
  border-radius: 1rem;
  box-shadow: 0px -10px 0px $color-primary;
  background-color: #171717;
  color: $color-lighter;
}

/*.exept {
  box-shadow: 0px -10px 0px #03a9f4;
}*/

@media (max-width: 767px) {
  .card-column {
    justify-content: center;
    align-items: center; /* Centrer la colonne */
  }

  .card {
    margin: 1rem 0.7rem 1rem;
    max-width: 80vw; /* Limite la largeur des cartes en version mobile */
    width: auto; /* Réinitialisation de la largeur par défaut */
  }
}

img {
  width: 6.2rem;
  border-radius: 5rem;
  margin: 1.3rem auto 1rem auto;
}

.col-md-4 {
  padding: 0 0.5rem;
}

.card-title {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: bold;
}

.card-text {
  text-align: center;
  padding: 1rem 2rem;
  font-size: 0.8rem;
  line-height: 1.4rem;
  flex-grow: 1; /* Laisse le contenu s'étirer dans le conteneur */
}

.footer {
  text-align: center;
  line-height: 1.2rem;
  padding: 2rem 0 1.4rem 0;
  margin-top: auto; /* Place le footer au bas de la carte */
}

#name {
  font-size: 0.8rem;
  font-weight: bold;
}

#position {
  font-size: 0.7rem;
}

a {
  color: $color-primary;
  font-weight: bold;
}

a:hover {
  color: $color-lighter;
}
</style>
