<template>
  <div class="container p-2 p-lg-5 z-depth-1">
    <section class="dark-grey-text text-center">
      <!----<h1 class="jaapokki text-white pt-5 pb-5">
        you<span class="menu__span">can</span>learn
      </h1>-->
      <TextHiting />
    </section>
  </div>
</template>

<script>
import TextHiting from "@/components/partials/TextHiting.vue";
export default {
  name: "HeroBlog",
  components: { TextHiting },
};
</script>

<style lang="scss" scoped>
h1 {
  color: $color-lighter;
  font-size: 3em;
  & span {
    color: $color-primary;
  }
}
img {
  width: 200px;
}
.hide {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
