<template>
  <div class="content" data-form-type="card">
    <a :href="href" class="btn" :style="{ color: textColor }">
      <span class="btn__circle" :style="{ boxShadow: circleBoxShadow }"></span>
      <span
        class="btn__white-circle"
        :style="{ background: whiteCircleBackground }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="icon-arrow-right"
          viewBox="0 0 21 12"
        >
          <path
            d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"
          ></path>
        </svg>
      </span>
      <span class="btn__text">En savoir plus</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "ArrowBtn",
  props: {
    href: {
      type: String,
      default: "/home",
    },
    textColor: {
      type: String,
      default: "rgb(31, 30, 30)", // Default white circle background color
    },
    whiteCircleBackground: {
      type: String,
      default: "#feda15", // Default white circle background color
    },
    circleBoxShadow: {
      type: String,
      default: "0 0 1px 1px #feda15;", // Default box shadow for the circle
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-bottom: 6px;
}
.btn {
  display: inline-block;
  color: #feda15;
  margin-top: 20px;
  padding: 32px;
  position: relative;
  letter-spacing: 1px;
  & ul,
  ol {
    list-style: none;
  }
  & a {
    text-decoration: none;
  }

  @media screen and (max-width: 992px) {
    .btn__circle {
      transform: scale(0);
    }

    .btn__white-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn__text {
      transform: translate(40px, -50%);
    }
  }

  &__circle,
  &__text,
  &__white-circle {
    position: absolute;
  }

  &__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    box-shadow: 0 0 2px 2px #feda15;
    transition: 0.3s linear;
  }

  &__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #feda15;
    display: flex;
    transition: 0.3s ease-in-out;

    svg {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }

  &__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 24px 8px;
    transition: 0.3s linear;
    color: $color-lighter;
  }

  &:hover {
    .btn__circle {
      transform: scale(0);
    }

    .btn__white-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn__text {
      transform: translate(40px, -50%);
    }
  }
}
</style>
