<template>
  <div class="container pt-5 mb-5 z-depth-1">
    <!--Section: Content-->
    <section class="about-tech">
      <!-- Section heading -->
      <h2
        class="text-center font-weight-bold mt-5 pt-5 mb-4 pb-2 image-fade-in"
      >
        Une application pour les former tous
      </h2>
      <!-- Section description -->
      <p class="text-center lead mx-auto mb-5 image-fade-in">
        you<span class="color-primary">can</span>learn est une application de
        <strong>Micro Learning</strong> conçue pour répondre aux besoins de
        communication et de <strong>formation des entreprises</strong>. Avec
        notre approche innovante, nous avons créé une plateforme qui simplifie
        et dynamise le processus de communication et d’apprentissage, tout en
        assurant une uniformité et une qualité inégalées.
      </p>

      <!-- Grid layout for four sections -->
      <div class="four-section">
        <!-- Section 1 -->
        <div class="section-item slide-left card-yellow">
          <div class="icon-container">
            <i class="fa fa-2x fa-handshake deep-purple-text"></i>
          </div>
          <div class="text-container">
            <h3 class="font-weight-bold mb-3">Nouveaux arrivants</h3>
            <p>
              Vidéos dédiés au parcours d’intégration du nouvel arrivant. Il y
              retrouve les infos clés de manière digeste et peut reconsommer et
              interagir.
            </p>
          </div>
        </div>

        <!-- Section 2 -->
        <div class="section-item slide-left card-yellow">
          <div class="icon-container">
            <i class="fa fa-2x fa-user-circle deep-purple-text"></i>
          </div>
          <div class="text-container">
            <h3 class="font-weight-bold mb-3">
              Sensibilisation à l’hygiène numérique
            </h3>
            <p>
              Vidéos présentant les plus grands dangers (social hacking) en
              entreprise ne pouvant être déjoués par de la technique.
            </p>
          </div>
        </div>

        <!-- Section 3 - Ajout de la classe "section-item-3" -->
        <div class="section-item slide-right card-yellow section-item-3">
          <div class="icon-container">
            <i class="fa fa-2x fa-bolt deep-purple-text"></i>
          </div>
          <div class="text-container">
            <h3 class="font-weight-bold mb-3">Marque employeur</h3>
            <p>
              Vidéos de promotion de certains postes à travers des vie ma vie et
              autres réalisations.
            </p>
          </div>
        </div>

        <!-- Section 4 - Ajout de la classe "section-item-4" -->
        <div class="section-item slide-right card-yellow section-item-4">
          <div class="icon-container">
            <i class="fas fa-2x fa-line-chart deep-purple-text"></i>
          </div>
          <div class="text-container">
            <h3 class="font-weight-bold mb-3">
              Harmonisation d’un message clé
            </h3>
            <p>
              Message clé que toute l’entreprise doit comprendre de la même
              manière, autrefois transmis en note interne.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "AboutTech",
};
</script>

<style lang="scss" scoped>
.color-primary {
  color: $color-primary;
}
.about-tech {
  padding: 0px 0px 70px;

  @media (min-width: 992px) {
    padding: 0px 20px 70px;
  }

  h2 {
    color: $color-lighter;
  }

  p {
    color: $color-lighter;
  }

  .four-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Grille avec deux colonnes */
    gap: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Une seule colonne en mode mobile */
    }
  }

  .section-item {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: 0.5rem;
    height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .card-yellow {
    //background: linear-gradient(315deg, #fff, #feda15);
    background-color: #feda15;
    h3 {
      color: $color-darker;
    }

    p {
      color: $color-darker;
    }

    i {
      color: #000000;
    }
  }

  .card-purple {
    background: linear-gradient(315deg, #03a9f4, #ba02caa3);

    h3 {
      color: $color-lighter;
    }

    p {
      color: $color-lighter;
    }

    i {
      color: $color-primary;
    }
  }

  .icon-container {
    flex: 0 0 auto;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    flex: 1;
  }

  /* Nouvelle règle CSS pour inverser l'ordre des deux derniers éléments en mobile */
  @media (max-width: 768px) {
    .section-item-3 {
      order: 2; /* Inverser l'ordre pour cet élément */
    }

    .section-item-4 {
      order: 1; /* Inverser l'ordre pour cet élément */
    }
  }
}
</style>
